<template>
	<v-row>
		<v-col class="col-12 pa-0"><hr></v-col>
		<v-col class="col-12 pa-0">
			<v-card elevation="0" class="">
				<v-card-text>
					<v-col class="col-12 pa-0"><b>Вет.клиника:</b> {{ petInfo.org_address }}</v-col>
					<v-col class="col-12 pa-0"><b>Врач:</b> {{ petInfo.doctor }}</v-col>
				</v-card-text>
			</v-card>
		</v-col>
	</v-row>
</template>

<script type="text/javascript">
export default {
	name: 'TemplateFooterComponent',
	props: ['petInfo'],
}

</script>
