<template>
	<v-row>
		<v-col class="col-12 pa-0"><hr></v-col>
		<v-col class="col-12 pa-0">
			<v-card elevation="0" class="">
				<v-card-title>
					<v-col class="col-12 text-h6 text-center text-uppercase font-weight-bold pa-0">Счет на предоставленные услуги</v-col>
					</v-card-title>
				<v-card-text>
					<v-col class="col-12 pa-0 text-caption" style='font-family: monospace !important;'>
						<v-data-table dense  hide-default-footer :headers='headers' :items="services" class="elevation-1" :items-per-page="itemsPerPage" >
							
							<template v-slot:item.sum="{ item }">
								{{ (item.price * item.count * (1 - item.discount/100)).toFixed(2) }}
							</template>

							<template v-slot:item.pricenew="{ item }">
								<span class="text-right">{{ (item.price).toFixed(2) }}</span>
							</template>

							<template v-slot:item.barcode="{ item }">
								<barcode :value="item.price" tag="svg" width="3" displayValue="false">{{ item }}</barcode>
							</template>

							<template v-slot:body.append>
								<tr class="pa-0 ma-0">
									<td colspan="4"><barcode :value="researchResult.barcode" tag="svg" width="3" height="25" displayValue="false"></barcode></td>
									<td class="text-right text-h5"><b>{{ sum }}</b></td>
									<td></td>
								</tr>
							</template>
						</v-data-table>
					</v-col>
				</v-card-text>
			</v-card>
		</v-col>
	</v-row>
</template>

<script type="text/javascript">

import VueBarcode from 'vue-barcode';

export default {
	name: 'TemplateCostComponent',
	components: {
		'barcode': VueBarcode,
	},
	props: ['researchResult'],
	data() {
		return {
			headers: [
				{text: 'Наименование', value:'name'}, 
				{text: 'Стоимость', value:'pricenew', align: 'end'},
				{text: 'Количество', value:'count', align: 'end'},
				{text: 'Скидка', value:'discount', align: 'end'},
				{text: 'Итого', value:'sum', align: 'end'},
				//{text: '', value:'barcode', align: 'end'},
			],
			itemsPerPage: -1,
		}
	},
	computed:{
		services: function(){
			// Выводим оба вида услуг и материалы
			var res = [].concat(this.researchResult.services_descripton)
			res = res.concat(this.researchResult.medicaments_descripton)
			return res
		},
		sum: function(){
			var sum = 0
			for (var serv in this.services) {
				var obj = this.services[serv]
				var price = obj.price * obj.count * (1- obj.discount/100)
				sum += price
			}
			return sum.toFixed(2)
		},
	},
}

</script>

<style>

tbody tr td {
	//font-size: 0.75rem !important;
}

@media print {
  tbody tr td {
	font-size: 0.5rem !important;
  }
}

</style>
