<template>
	<v-row>
		<v-col class="col-6 pa-0">
			<v-card elevation="0" class="pa-0 ma-0">
				<v-card-text class="text-caption pt-0">
					<v-col class="pa-0"><b>Кличка животного:</b>               {{ petInfo.name }}</v-col>
					<v-col class="pa-0"><b>Вид животного:</b>                  {{ petInfo.kindOfAnimal }}</v-col>
					<v-col class="pa-0"><b>Порода животного:</b>               {{ petInfo.breed }}</v-col>
					<v-col class="pa-0"><b>Пол животного:</b>                  {{ petInfo.sex }}</v-col>
					<v-col class="pa-0"><b>Возраст животного:</b>        {{ petInfo.age }} ({{ petInfo.age_year }} лет)</v-col>
				</v-card-text>
			</v-card>
		</v-col>
		<v-col class="col-6 pa-0">
			<v-card  elevation="0" class="pa-0 ma-0">
				<v-card-text class="text-caption pt-0">
					<v-col class="pa-0"><b>Дата приема:</b> {{ petInfo.create_date }}</v-col>
					<v-col class="pa-0"><b>Владелец:</b> {{ petInfo.owner }} </v-col>
					<v-col class="pa-0" v-if="petInfo.diagnosis"><b>Диагноз:</b> <i>{{ petInfo.diagnosis }}</i></v-col>
				</v-card-text>
			</v-card>
		</v-col>
	</v-row>
</template>

<script type="text/javascript">
export default {
	name: 'TemplateAnimalInfoComponent',
	props: ['dateReceipt', 'petInfo'],
}

</script>
