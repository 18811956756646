<template>
	<div v-if="researchResult">
		<TemplateHeader :orgInfo=orgInfo></TemplateHeader>
		<TemplateAnimalInfoComponent :petInfo=researchResult></TemplateAnimalInfoComponent>

		<v-row v-if="researchResult.description">
			<v-col class="col-12 text-h5 text-center text-uppercase font-weight-bold pa-0">{{ researchResult.template_name }}</v-col>
		</v-row>

		<v-row>
			<v-col class="researchResult" >
				<div v-html="researchResult.description"></div>
			</v-col>
		</v-row>
		<TemplateCostComponent :researchResult=researchResult v-if="researchResult.medicaments_descripton||researchResult.services_descripton"></TemplateCostComponent>

		<TemplateFooterComponent :petInfo=researchResult></TemplateFooterComponent>
	</div>
	<div v-else>
		Ошибка получения данных
	</div>
</template>

<script type="text/javascript">


import TemplateHeader from '@/components/TemplateHeaderComponent.vue'
import TemplateAnimalInfoComponent from '@/components/TemplateAnimalInfoComponent.vue'
import TemplateFooterComponent from '@/components/TemplateFooterComponent.vue'
import TemplateCostComponent from '@/components/TemplateCostComponent.vue'

const url = new URL(window.location);

export default {
	name:'Result',
	components: {
		TemplateHeader,
		TemplateAnimalInfoComponent,
		TemplateFooterComponent,
		TemplateCostComponent,
	},
	data: ()=>({}),
	methods:  {
		getInfo() {
			var research = url.searchParams.get('research')
			var reception = url.searchParams.get('reception')
			// Получаем информацию по исследованиям
			if (research) {this.$store.dispatch('uploadAnimalResearchAsync', {id:research, db:'research'})}
			// Получаем информацию по приемам
			if (reception) {this.$store.dispatch('uploadAnimalResearchAsync', {id:reception, db:'reception'})}
			/*if (!this.researchResult.description && !this.researchResult.diagnosis) {
				console.log('--------------------ERROR')
				alert('Не указан диагноз, либо не корректные параметры')
			}*/
		},
	},
	computed: {
		researchResult() { return this.$store.getters.getAnimalResearch },
		orgInfo() { return this.$store.getters.getOrgInfo },
	},
	beforeMount() {
		console.log("INIT")
		this.$store.dispatch('uploadOrgInfoAsync')
		this.getInfo()
	}
}

</script>

<style>
.level2 {
	font-size:24px;
	font-weight: bold;
	display: flex;
	justify-content: left;
	/*border-bottom: 1px solid black;*/
}

.container { 
	size: A4 portrait !important;
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td, .v-data-table > .v-data-table__wrapper > table > thead > tr > td, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > td  { height: 0px;}

.researchResult {
	line-height:1rem !important;
	font-size:14px;	
}

@media print {
	.v-app-bar {
		background-color:red !important;
		height: 1px !important;
		display:none;
	}
	.container {
		size: A4 portrait !important;
		padding: 0px !important;
		margin: 0px !important;
	}
	.researchResult {
		line-height:1rem !important;
		line-height:1rem !important;
		font-size:12px;	
	}
}
</style>