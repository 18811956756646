<template>
	<v-row class="b-1">
		<v-col class="col-2"><img :src="orgInfo.logo"  :alt="orgInfo.name"  height="74" width="74"></v-col>
		<v-col class="col-5 ">
			<v-row>
				<v-col class="col-12 text-h4 font-weight-black pb-0 ma-0">{{ orgInfo.name }}</v-col>
				<v-col class="col-12 pa-0 pt-0 text-caption" v-for="address in orgInfo.address" :key="address">{{ address }} </v-col>
			</v-row>
		</v-col>
		<v-spacer></v-spacer>

		<v-col class="col-4  justify-end text-caption">
			Тел: {{ orgInfo.phones[0] }}<br>
			&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ orgInfo.phones[1] }}<br>
			Сайт: {{ orgInfo.site }}<br>
			E-Mail: {{ orgInfo.email }}<br>
		</v-col>

		<v-col class="col-12 pa-0"><hr></v-col>
	</v-row>
</template>

<script type="text/javascript">
export default {
	name: 'TemplateHeaderComponent',
	props: ['orgInfo'],
}

</script>
